import styled from "styled-components"

export const Wrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
`

export const Image = styled.img`
  filter: grayscale(100%) contrast(0) brightness(1.5);
`
