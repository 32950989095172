import React from "react"
import Splash from "src/views/Splash/Splash"
import SEO from "../components/seo"
import OGImage from "../images/seo/share_index.jpg"

const LandingPage = () => (
  <>
    <SEO
      title="95 lat Polskiego Radia"
      description="Multimedialny przewodnik po liczącej 95 lat historii Polskiego Radia"
      image={OGImage}
    />
    <Splash />
  </>
)

export default LandingPage
