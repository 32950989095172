import React from "react"
import { Wrapper, Image } from "./styles"

const Link = ({ image, href }) => {
  return (
    <Wrapper href={href}>
      <Image src={image} />
    </Wrapper>
  )
}

export default Link
