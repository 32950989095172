import styled from "styled-components"
import { motion } from "framer-motion"
export const SplashBg = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #030c19;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
`

export const SplashWrapper = styled(motion.div)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Logo = styled.img`
  margin-bottom: 30%;
  width: 60%;
`
