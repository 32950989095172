import React from "react"
import { isMobile } from "react-device-detect"
import Desktop from "./desktop"
import Mobile from "./mobile"

const Splash = () => {
  if (isMobile) return <Mobile />
  return <Desktop />
}

export default Splash
