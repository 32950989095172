import React from "react"
import Link from "src/components/Link"
import Logo1 from "images/jedynka.png"
import Logo2 from "images/dwojka.png"
import Logo3 from "images/trojka.png"
import Logo4 from "images/czworka.png"
import Logo24 from "images/pr24.png"
import LogoPRD from "images/dzieci.png"
import LogoPR from "images/pr.png"
import Logo95 from "images/logo_big.png"
import { SplashBg, SplashWrapper, Logo, LinksContainer } from "./styles"
import { AnimatePresence } from "framer-motion"
import { useTriggerTransition } from "gatsby-plugin-transition-link"
import { fade } from "./fade"

const Splash = () => {
  const triggerTransition = useTriggerTransition()

  return (
    <SplashBg>
      <AnimatePresence>
        <SplashWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 1, 0] }}
          transition={{ duration: 3, times: [0, 0.4, 0.6, 1] }}
          onAnimationComplete={() =>
            triggerTransition({
              to: "/poczatek-radia",
              exit: {
                length: 1,
                trigger: ({ exit, node }) =>
                  fade({ exit, node, direction: "out" }),
              },
              entry: {
                length: 0.5,
                delay: 0.5,
                trigger: ({ exit, node }) =>
                  fade({ exit, node, direction: "in" }),
              },
            })
          }
        >
          <Logo src={Logo95} />
        </SplashWrapper>
      </AnimatePresence>
    </SplashBg>
  )
}

export default Splash
